/* page content */
.page {
    padding-top: 150px;
    padding-left: 0;
    padding-right: 0;
	.news1, .news {
		.date {
			color: #b49157;
		}
		a {
			text-decoration: underline;
			&:hover {
				color: #b49157;
				text-decoration: none;
			}
		}
	}
	.page_nav {
		.active {
			color: #b49157;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #af8a52;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    > .left, > .right {
        @include inline-block;
    }
    > .left {
        width: calc(100% - 240px);
    }
    > .right {
        width: 230px;
        float: right;
    }
    h1 {
        margin-bottom: 30px;
    }
	@media screen and (max-width: 1170px) {
		font-size: 16px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
    @media screen and (max-width: 1140px) {
        padding-top: 0;
    }
    @media screen and (max-width: 991px) {
        > .left, >.right {
            display: block;
            width: 100%;
            float: none;
        }
        > .right {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 767px) {
        h1 {
            margin-bottom: 20px;
        }
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
	font-size: 14px;
    padding: 22px 0;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #957546;
			@include inline-block;
			a {
				text-decoration: none;
                color: #454545;
                font-size: 14px;
                &:hover {
                    color: #957546;
                }
			}
			&:after {
				content: '';
                width: 2px;
                height: 13px;
                background-color: #7f7d79;
				margin: 0px 5px 0px 10px;
				color: #454545;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 991px) {
        padding: 15px 0;
    }
}
/* breadcrumbs */


/* calendar */
.calendar {
    display: block;
    background-color: #e1d3bc;
    padding: 22px 25px;
    line-height: 1;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    h4 {
        font-size: 20px;
        text-transform: uppercase;
        color: #af8a52;
        margin: 0 0 10px;
    }
    > span {
        display: block;
        font-size: 16px;
        color: #454545;
        margin-bottom: 6px;
    }
    .dropdown {
        width: 100%;
        font-size: 14px;
        box-shadow: none!important;
        margin-bottom: 10px;
        .selected {
            min-height: 30px;
            padding: 8px 25px 8px 5px;
            box-shadow: inset 2px 3px 0px #f2f6f3;
            &:after {
                box-shadow: none;
            }
        }
        .carat {
            width: 10px;
            height: 8px;
            right: 8px;
            background: none;
            &:after {
                content: '';
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid #af8a52;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
        div {
            border: 1px solid #af8a52;
            border-radius: 0px;
            ul {
                li {
                    border-top: 1px solid #fff;
                    padding: 8px 5px;
                    box-sizing: border-box;
                    &.focus,&.active {
                        background-color: #e1d3bc;
                    }
                    &:first-child {
                        border-top: none;
                    }
                    &:last-child {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
    .calendar__date {
        position: relative;
        margin-bottom: 10px;
        &:after {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            pointer-events: none;
            background: url(../img/img-20.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 8px;
            right: 5px;
        }
        input[type="text"] {
            min-height: 30px;
            background-color: #fff;
            font-size: 14px;
            padding: 7px 25px 6px 5px;
            box-shadow: inset 2px 3px 0px #f2f6f3;
            outline: none;
            margin: 0;
        }
    }
    .btn {
        height: 44px;
        width: 100%!important;
        font-size: 14px;
        font-weight: bold;
        background-color: #ffffff!important;
        color: #af8a52!important;
        border: 2px solid #fff!important;
        padding: 12px 20px!important;
        margin-top: 20px;
        &:hover {
            background-color: #af8a52!important;
            color: #fff!important;
            border: 2px solid #af8a52!important;
        }
    }
}

.ui-datepicker {
    border-radius: 0px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    .ui-widget-header {
        background-color: #e1d3bc;
        border-radius: 0px;
    }
    .ui-datepicker-title {
        font-family: $regular;
        font-weight: bold;
    }
    .ui-state-default {
        background-color: #e1d3bc;
    }
    .ui-state-highlight {
        background-color: #ffeccc;
    }
    .ui-state-hover, .ui-state-active {
        background-color: #af8a52;
        border: 1px solid #af8a52;
        color: #fff;
    }
    .ui-datepicker-next,
    .ui-datepicker-prev {
        border: none;
        border-radius: 0px!important;
        transition: 300ms;
        cursor: pointer;
        &:hover {
            background-color: transparent;
            border: none;
            border-radius: 0px!important;
            font-weight: normal;
        }
        &:after{
            font-family: 'fontAwesome';
            font-size: 14px;
            color: #af8a52;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            text-align: center;
            transform: translateY(-50%);
            line-height: 1;    
        }
    }
    .ui-datepicker-next {
        &:after {
            content: '\f054';
        }
        &:hover {
            right: 2px;
            top: 2px;
        }
    }
    .ui-datepicker-prev {
        &:after {
            content: '\f053';
        }
        &:hover {
            left: 2px;
            top: 2px;
        }
    }
}
/* calendar */


.right--contacts {
    min-height: 500px;
    background-color: rgba(255,255,255,0.7);
    padding: 25px 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    margin-top: 10px;
    h4 {
        font-size: 20px;
        text-transform: uppercase;
        color: #af8a52;
        white-space: nowrap;
        margin: 0;
    }
    h6 {
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 15px;
        margin-top: 30px;
    }
    ul {
        @include ul-default;
        padding-left: 15px;
        li {
            display: block;
            padding-left: 18px;
            margin: 14px 0;
            &:after {
                font-size: 18px;
                font-weight: bold;
            }
            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        min-height: 0px;
        padding: 25px 20px;
        h6 {
            margin-top: 20px;
        }
    }
}

.content {
    background-color: rgba(255,255,255,0.7);
    padding: 35px 30px 80px;

    h5 {
        margin: 15px 0;
    }

    /* page catalog */
    .content__slider--for {
        @include inline-block;
        width: calc(100% - 220px);
        margin-bottom: 20px;
        .slick-slide {
            height: 250px;
            width: 100%;
            outline: none;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .slick-arrow {
            width: 32px;
            height: 17px;
            background: #b49157 url(../img/arrow.png) no-repeat center center;
            background-size: 15px;
            z-index: 1;
            transition: 300ms;
            &:before {
                display: none;
            }
            &.slick-prev {
                left: 10px;
                transform: rotate(180deg) translate(0,50%);
            }
            &.slick-next {
                right: 10px;
            }
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .content__slider--nav {
        @include inline-block;
        width: 210px;
        float: right;
        margin-top: -5px;
        .slick-slide {
            height: 120px;
            width: 100%;
            outline: none;
            position: relative;
            margin: 5px 0;
            cursor: pointer;
            &:after {
                content: '';
                background-color: rgba(255, 255, 255, 0.5);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                z-index: 1;
                transition: 300ms;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
            &:hover:after, &.slick-current:after {
                opacity: 1;
            }
        }
    }

    .cat {
        font-size: 0;
        padding: 0;
        text-align: center;
        .row {
            .col-sm-4 {
                padding: 0 12px;
            }
            .col-md-4 {
                @include inline-block;
                float: none;
            }
        }
        .cat__block {
            text-align: left;
            font-size: 16px;
            .cat__block__title {
                display: block;
                background-color: #f2ebdf;
                font-size: 24px;
                text-transform: uppercase;
                text-align: center;
                padding: 12px;
                line-height: 1;
            }
            .cat__block__for {
                margin-bottom: 10px;
                .slick-slide {
                    height: 175px;
                    outline: none;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
            }
            .cat__block__nav {
                margin: 0 -7px 25px;
                .slick-slide {
                    height: 60px;
                    position: relative;
                    outline: none;
                    margin: 0 7px;
                    cursor: pointer;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(255,255,255,0.5);
                        transition: 300ms;
                        opacity: 0;
                    }
                    &:hover {
                        &:after {
                            opacity: 1;
                        }
                    }
                    &.slick-current:after {
                        opacity :1;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                .slick-arrow {
                    width: 32px;
                    height: 17px;
                    background: #b49157 url(../img/arrow.png) no-repeat center center;
                    background-size: 15px;
                    z-index: 1;
                    transition: 300ms;
                    &:before {
                        display: none;
                    }
                    &.slick-prev {
                        left: 0;
                        transform: rotate(180deg) translate(0,50%);
                    }
                    &.slick-next {
                        right: 0;
                    }
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .price {
                display: block;
                font-size: 26px;
                text-align: center;
                margin-bottom: 20px;
                span {
                    font-weight: bold;
                    color: #b49157;
                }
            }
            ul {
                @include ul-default;
                min-height: 420px;
                margin-top: 20px;
            }
            .cat__block__list {
                li {
                    min-height: 30px;
                    position: relative;
                    padding: 0 15px 0 45px;
                    margin: 3px 0;
                    img {
                        display: block;
                        width: 35px;
                        height: 35px;
                        object-fit: contain;
                        object-position: center top;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
            }
            .btn {
                padding: 15px 25px!important;
                margin: 35px auto 0;
            }
        }
    }
    .about__img {
        max-width: 430px;
        margin-top: 0;
        margin-bottom: 60px;
        .img {
            max-width: 275px;
            bottom: -25px;
        }
    }
    .about__list {
        @include ul-default;
        li {
            min-height: 50px;
            position: relative;
            padding-left: 55px;
            margin: 20px 0;
            img {
                width: 45px;
                height: 40px;
                object-fit: contain;
                object-position: top center;
                position: absolute;
                top: 0;
                left: 0;
            }
            strong {
                display: block;
                margin-bottom: 5px;
            }
        }
    }
    .about__block {
        a {
            display: block;
            background-color: #f5f5f5;
            padding: 15px;
            margin: 25px 0;
            box-shadow: 0px 5px 25px rgba(0,0,0,0.2);
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                transition: 300ms;
            }
            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
    }
    .circle--list {
        li {
            padding-left: 30px;
            margin: 25px 0;
        }
    }
    /* page catalog */

    /* page contacts */
    .contacts {
        ul {
            @include ul-default;
            margin-bottom: 30px;
            li {
                margin: 20px 0;
                a {
                    display: block;
                }
            }
        }
        h3 {
            font-size: 26px;
            font-weight: normal;
            margin: 0 0 20px 0;
        }
        .left {
            h3 {
                padding-left: 25px;
            }
        }
        .right h3 {
            text-align: center;
        }
        .map {
            width: 100%;
            height: 355px;
            > ymaps, .ymaps-2-1-69-map {
                width: 100%!important;
                height: 100%!important;
            }
        }
        form {
            display: block;
            .g-recaptcha {
                display: table;
                margin: 10px auto;
            }
            .btn {
                margin: 15px auto 0;
            }
        }
    }
    /* page contacts */

    @media screen and (max-width: 991px) {
        padding: 25px 20px 40px;
        .cat {
            .row {
                .col-md-4 {
                    display: block;
                }
            }
            .cat__block {
                margin-bottom: 25px;
                .cat__block__title {
                    font-size: 20px;
                }
                .cat__block__for .slick-slide {
                    height: 300px;
                }
                .cat__block__nav .slick-slide {
                    height: 120px;
                }
                .price {
                    font-size: 20px;
                }
                .btn {
                    margin-top: 25px;
                }
                ul {
                    min-height: 0px;
                }
            }
        }
        .contacts {
            h3 {
                font-size: 22px;
            }
            .left h3 {
                padding-left: 0;
            }
            .map {
                height: 250px;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 767px) {
        .content__slider--for {
            width: calc(100% - 150px);
            .slick-slide {
                height: 200px;
            }
        }
        .content__slider--nav {
            width: 140px;
            .slick-slide {
                height: 95px;
            }
        }
        .cat .cat__block .btn {
            padding: 10px 25px!important;
        }
    }
    @media screen and (max-width: 580px) {
        .cat .cat__block {
            .cat__block__for {
                .slick-slide {
                    height: 250px;
                }
            }
            .cat__block__nav {
                .slick-slide {
                    height: 100px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .content__slider--for {
            width: 100%;
        }
        .content__slider--nav {
            display: block;
            width: auto;
            float: none;
            margin: 0 -10px 20px;
            .slick-slide {
                margin: 0 10px;
            }
        }
        .cat .cat__block {
            .cat__block__for {
                .slick-slide {
                    height: 200px;
                }
            }
            .cat__block__nav {
                .slick-slide {
                    height: 80px;
                }
            }
        }
        .about__list {
            li {
                padding-left: 35px;
                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 380px) {
        .content__slider--nav {
            .slick-slide {
                height: 80px;
            }
        }
        .cat .cat__block {
            .cat__block__for {
                .slick-slide {
                    height: 180px;
                }
            }
            .cat__block__nav {
                .slick-slide {
                    height: 70px;
                }
            }
        }
        .contacts {
            form {
                .g-recaptcha {
                    width: 250px;
                    transform: scale(0.8);
                    transform-origin: 0% 50%;
                    margin: 10px 0;
                }
            }
        }
    }
}

/* page hostel */
.hostel {
    height: calc(100vh - 230px);
    padding: 135px 55px 25px;
    .hostel__col {
        display: block;
        position: relative;
        font-size: 0;
        margin: 15px 0;
        box-shadow: 0px 0px 55px rgba(0,0,0,0.4);
        &:before {
            content: '';
            border: 7px solid #fefaf5;
            position: absolute;
            top: 20px;
            left: -22px;
            right: 22px;
            bottom: -20px;
        }
        .box {
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            z-index: 1;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .hostel__col__block {
            width: 50%;
            height: calc(50vh - 170px);
            font-size: 18px;
            font-weight: bold;
            background-color: #fffbf6;
            .hostel__col__block__title {
                display: table;
                font-family: $andantino;
                font-size: 55px;
                font-weight: normal;
                color: #af8a52;
                white-space: nowrap;
                padding-top: 25px;
                margin: 0 auto 30px;
            }
            ul {
                @include ul-default;
                display: table;
                position: relative;
                margin: 0 auto;
                z-index: 2;
                li {
                    margin: 10px 0;
                    a {
                        display: block;
                        font-size: 18px;
                    }
                }
            }
            h4 {
                font-weight: bold;
                font-size: 22px;
                color: #414141;
                text-align: center;
                text-transform: uppercase;
                padding-top: 25px;
            }
        }
        .hostel__col__img {
            width: 50%;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                transition: 300ms;
            }
        }
        .btn {
            display: block;
            width: 220px!important;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 28px;
            padding: 15px 10px!important;
            margin: 0 auto;
            z-index: 2;
        }
        &:hover {
            .hostel__col__img {
                img {
                    transform: scale(1.1);
                }
            }
        }
        &.last {
            filter: grayscale(100%);
            pointer-events: none;
        }
    }
    @media screen and (max-width: 1440px) {
        height: auto;
        .hostel__col {
            .hostel__col__block {
                height: 250px;
                .hostel__col__block__title {
                    font-size: 35px;
                    margin-bottom: 10px;
                }
            }
            .btn {
                bottom: 10px;
            }
        }
        .col-md-4 {
            .hostel__col .hostel__col__block {
                width: 65%;
                position: relative;
            }
        }
    }
    @media screen and (max-width: 1140px) {
        padding: 20px 30px;
    }
    @media screen and (max-width: 991px) {
        .hostel__col {
            margin: 0 0 30px;
            .hostel__col__block {
                padding-bottom: 50px;
            }
            .btn {
                height: auto;
                padding: 10px 15px!important;
            }
        }
        .col-md-4 {
            .hostel__col .hostel__col__block {
                width: 50%;
            }
        }
    }
    @media screen and (max-width: 580px) {
        padding: 40px 20px 10px;
        .hostel__col {
            &:before {
                top: 15px;
                left: -15px;
                bottom: -15px;
                right: 15px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding-top: 30px;
        .hostel__col {
            .box {
                top: 4px;
                right: 4px;
                bottom: 4px;
                left: 4px;
                border: 2px solid #b49157;
                img {
                    display: none;
                }
            }
            .hostel__col__img {
                width: 100%;
                position: relative;
                img {
                    height: auto;
                }
            }
            .hostel__col__block {
                width: 100%!important;
                height: auto;
                padding-bottom: 70px;
            }
            .btn {
                bottom: 25px;
            }
        }
    }
}   
/* page hostel */

/* modal */
.modal {
    .modal-dialog {
        top: 45%;
        margin: 0 auto;
        transform: translateY(-50%)!important;
    }
    .modal-content {
        width: auto;
        max-width: 420px;
        border-radius: 0px;
        margin: 0 auto;
        h4 {
            font-family: $bold;
            font-size: 18px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 140px;
        margin-top: 25px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            display: block;
            width: 100%;
            min-height: 40px;
            background-color: #f2f2f2;
            font-family: $regular;
            font-size: 16px;
            color: #414141;
            padding: 11px 20px;
            margin-bottom: 15px;
            border: none;
            line-height: 1;
            outline: none;
            &::placeholder {
                color: #999999;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {
        .modal-content {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 420px) {
        .modal-dialog {
            margin: 0 10px;
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .modal-content {
            form {
                .g-recaptcha {
                    transform: scale(0.85);
                    transform-origin: 100% 50%;
                }
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 35px;
    display: block;
    background-color: #af8a52;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 25px 5px 5px 5px;
    box-shadow: 0px 0px 35px rgba(0,0,0,0.7);
    outline: none;
    z-index: 2;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 34px;
        color: #fff;
    }
    &:after,&:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #af8a52;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
    }
    @media screen and (max-width: 1140px) {
        bottom: 30px;
        right: 20px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 13px;
        right: 15px;
        bottom: 30px;
        i {
            font-size: 24px;
        }
    }
}
@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

.btn--up {
    display: block;
    background-color: #af8a52;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
    border: 2px solid #af8a52;
    position: fixed;
    bottom: 25px;
    right: 30px;
    padding: 5px 10px;
    z-index: 1;
    line-height: 1;
    opacity: 0;
    &:focus {
        color: #fff;
    }
    &:hover {
        background-color: #fff;
        text-decoration: none;
        color: #af8a52;
        opacity: 0.7;
    }
    @media screen and (max-width: 1140px) {
        display: none;
    }
}

/* page content */