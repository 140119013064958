/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 29, 2018 */
@font-face {
    font-family: 'andantino_scriptregular';
    src: url('../fonts/andantionscript-webfont.eot');
    src: url('../fonts/andantionscript-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/andantionscript-webfont.woff2') format('woff2'),
         url('../fonts/andantionscript-webfont.woff') format('woff'),
         url('../fonts/andantionscript-webfont.ttf') format('truetype'),
         url('../fonts/andantionscript-webfont.svg#andantino_scriptregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

$regular: 'Times New Roman', sans-serif;
$bold: 'Times New Roman', sans-serif;
$light: 'Times New Roman', sans-serif;
$andantino: 'andantino_scriptregular';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    background-image: url(../img/img-21.jpg);
    background-repeat: repeat;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    color: #414141;
    line-height: 1.2;
	margin: 0;
    &.body {
        background-image: url(../img/img-23.jpg);
        background-repeat: repeat;
    }
    @media screen and (max-width: 1140px) {
        padding-top: 100px;
    }
    @media screen and (max-width: 680px) {
        padding-top: 87px;
    }
}

h1 {
	font-family: $bold;
	font-size: 38px;
    font-weight: bold;
	text-transform: uppercase;
	color: #b49157;
	line-height: 1;
	margin: 0 0 40px 0;
	@media screen and (max-width: 1199px) {
		font-size: 32px;
        margin-bottom: 25px;
	}
    @media screen and (max-width: 991px) {
        font-size: 28px;
        margin-bottom: 15px;
    }
	@media screen and (max-width: 768px) {
		font-size: 24px;
	}
}
h2 {
	font-family: $bold;
	font-size: 36px;
    font-weight: bold;
	color: #b49157;
    text-transform: uppercase;
	margin: 0 0px 40px;	
	a {
		font-family: $bold;
		font-size: 36px!important;
		color: #b49157;
        &:hover {
            color: #414141;
            text-decoration: none;
        }
	}
	@media screen and (max-width: 1199px) {
		font-size: 30px;
        margin-bottom: 25px;
		a {
			font-size: 30px!important;
		}
	}
    @media screen and (max-width: 991px) {
        font-size: 26px;
        margin-bottom: 15px;
    }
	@media screen and (max-width: 768px) {
		font-size: 22px;
		a {
			font-size: 24px!important;
		}
	}
}
h3 {
	font-family: $bold;
    font-weight: bold;
	font-size: 24px;
	color: #414141;
	@media screen and (max-width: 1199px) {
		font-size: 22px;
	}
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
}
h4 {
	font-family: $bold;
    font-weight: bold;
	font-size: 22px;
	color: #414141;
    @media screen and (max-width: 991px) {
        font-size: 20px;
    }
    @media screen and (max-width: 767px) {
        font-size: 18px;
    }
}
h5 {
	font-family: $bold;
    font-weight: bold;
	font-size: 18px;
	color: #414141;
}
h6 {
	font-family: $bold;
    font-weight: bold;
	font-size: 16px;
	text-transform: uppercase;
	color: #414141;
}
a {
    color: #414141;
	transition: 350ms;
	text-decoration: none;
	&:focus {
		color: #414141;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
        color: #b49157;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
    width: 1170px;
	position: relative;
	flex: 1 0 auto;
    font-size: 16px;
    line-height: 1.3;
    padding: 0 15px;
    margin: 0 auto;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        display: block;
        width: 100%;
        min-height: 40px;
		background-color: #f2f2f2;
		font-family: $regular;
		font-size: 16px;
        color: #414141;
		padding: 11px 20px;
        margin-bottom: 15px;
        border: none;
        line-height: 1;
        outline: none;
        &::placeholder {
            color: #999999;
        }
	}
    @media screen and (max-width: 1170px) {
        width: 100%;
    }
}
.main {
    background-color: rgba(255,255,255,0.6);
	.container {
		padding-top: 50px;
		padding-bottom: 50px;
		@media screen and (max-width: 1170px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
}
.btn {
	display: table;
    height: 50px;
	width: auto!important;
	background-color: #b49157!important;
	font-family: $bold!important;
	font-size: 16px;
	color: #fff!important;
    text-transform: uppercase;
	cursor: pointer;
	border-radius: 0px;
	border: 2px solid #b49157;
	padding: 10px 45px!important;
    line-height: 1;
    outline: none!important;
	transition: 300ms;
	&:focus, &:active {
		background-color: #b49157!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #fff!important;
		color: #b49157!important;
	}	
	@media screen and (max-width: 768px) {
		height: 40px;
        padding: 5px 25px!important;
	}
}
a.btn {
    padding: 15px 35px!important;
    @media screen and (max-width: 768px) {
        height: 40px;
        padding: 10px 25px!important;
    }
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		font-size: 16px;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}
.circle--list {
    @include ul-default;
    font-size: 16px;
    li {
        padding-left: 22px;
        position: relative;
        margin: 4px 0;
        &:after {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #b49157;
            border-radius: 100%;
            position: absolute;
            left: 4px;
            top: 7px;
        }
    }
}

.col--location,
.col--phone,
.col--email {
	position: relative;
	padding-left: 25px;
	font-size: 16px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 20px;
		color: #bc9c68;
		position: absolute;
		top: 0px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
}
.col--phone:after {
	content: '\f095';
}
.col--email {
    &:after {
        content: '\f0e0';
        font-size: 16px;
        top: 2px;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.close {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    opacity: 1;
    outline: none;
    z-index: 1;
    &:before, &:after {
        content: '';
        width: 20px;
        height: 2px;
        background-color: #af8a52;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        opacity: 1;
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}

.line {
    position: relative;
    z-index: 1;
    &:after {
        content: '';
        background: url('../img/line.png') no-repeat center top;
        background-size: contain;
        height: 15px;
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: -5px;
        margin: 0 auto;
        z-index: -1;
    }
}

/* header */
header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    .up {
        background-color: #f2ebdf;
        box-shadow: 0px 0px 7px rgba(0,0,0,0.3);
    }
    .down {
        font-size: 0;
    }
    .container {
        width: 1170px;
        padding: 0;
        position: relative;
    }
    ul {
        @include ul-default;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    .choose--btn {
        @include inline-block;
        height: 40px;
        width: 230px;
        position: relative;
        font-weight: bold;
        background-color: #c8af88;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        line-height: 1;
        padding: 11px 20px 11px 40px;
        &:after {
            content: '\f100';
            font-family: 'fontAwesome';
            font-size: 20px;
            color: #fff;
            position: absolute;
            top: 8px;
            left: 38px;
            transition: 300ms;
        }
        &:hover {
            &:after {
                left: 30px;
            }
        }
    }
    .slogan {
        @include inline-block;
        font-weight: bold;
        font-size: 18px;
        color: #454545;
        margin: 8px 0 0 40px;
    }

    /* header phones */
    .phones {
        position: relative;
        padding-left: 25px;
        margin: 10px 0 0 90px;
        @include inline-block;
        &:after {
            content: '\f095';
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #af8a52;
            position: absolute;
            left: 0;
            top: 2px;
        }
        ul {
            li {
                @include inline-block;
                a {
                    display: block;
                    font-weight: bold;
                    font-size: 16px;
                    color: #454545;
                    text-decoration: none;
                }
            }
            span {
                @include inline-block;
                font-size: 16px;
                margin: 0px 5px 0;
            }
        }
    }
    /* header phones */

    /* header search */
    .search--btn {
        display: block;
        width: 18px;
        height: 18px;
        background-image: url(../img/search.png);
        background-size: contain;
        background-position: center center;
        position: absolute;
        top: 11px;
        right: 85px;
    }
    .search {
        width: 0px;
        position: absolute;
        top: 5px;
        right: 80px;
        overflow: hidden;
        transition: 300ms;
        box-sizing: border-box;
        border: 1px solid transparent;
        z-index: 1;
        input[type="search"] {
            width: 100%;
            font-size: 16px;
            padding: 5px 30px 5px 15px;
            border: none;
            box-shadow: none;
            outline: none;
            line-height: 1;
            &::placeholder {
                font-style: italic;
            }
        }
        &.open {
            width: 300px;
            background-color: #fff;
            border: 1px solid #af8a52;
        }
        .close {
            top: 4px;
        }
    }
    /* header search */

	/* header logo */
	.logo {
        @include inline-block;
        width: 230px;
        height: 100px;
        background-color: #f2ebdf;
        font-family: $andantino;
        font-size: 42px;
        color: #af8a52;
        position: relative;
        padding-top: 30px;
        text-align: center;
        box-sizing: border-box;
        margin: 5px 5px 0 0;
        line-height: 1;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
        &:after {
            content: '';
            background: url('../img/line.png') no-repeat;
            background-size: contain;
            height: 15px;
            position: absolute;
            left: 40px;
            right: 40px;
            bottom: 20px;
            margin: 0 auto;
        }
        span {
            display: block;
            position: relative;
            z-index: 1;
        }
    }
	/* header logo */

	/* header nav */
	.nav {
        @include inline-block;
        width: calc(100% - 235px);
        background-color: #ffffff;
        margin-top: 5px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
        text-align: justify;
        text-align-last: justify;
        padding: 0 10px;
        > ul > li {
            @include inline-block;
            margin: 0 0px;
            > a {
                display: block;
                font-size: 16px;
                color: #454545;
                text-transform: uppercase;
                position: relative;
                padding: 39px 0;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 5px;
                    right: 5px;
                    border-top: 0px solid #b49157;
                    border-bottom: 0px solid #b49157;
                    transition: 300ms;
                    opacity: 0;
                }
                &:hover {
                    &:before {
                        border-top: 4px solid #b49157;
                        border-bottom: 4px solid #b49157;
                        opacity: 1;
                    }
                }
            }
            &.active > a {
                &:before {
                    border-top: 4px solid #b49157;
                    border-bottom: 4px solid #b49157;
                    opacity: 1;
                }
            }
        }
    }
	/* header nav */

	/* header langs */
	.langs {
        max-height: 22px;
        position: absolute;
        top: 7px;
        right: 15px;
        padding: 5px 22px 5px 5px;
        overflow: hidden;
        transition: 300ms;
        line-height: 1;
        z-index: 1;
        &:after {
            content: '\f107';
            font-family: 'fontAwesome';
            font-size: 16px;
            color: #af8a52;
            position: absolute;
            right: 5px;
            top: 4px;
        }
        ul{
            li {
                margin-bottom: 4px;
                a {
                    display: block;
                    font-size: 16px;
                    color: #454545;
                    text-decoration: none;
                    &:hover {
                        color: #af8a52;
                    }
                }
                &.active a {
                    text-decoration: none;                
                }
            }
        }
        &:hover {
            background-color: #af8a52;
            max-height: 150px;
            &:after {
                color: #fff;
            }
            ul li {
                a {
                    color: #fff;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &.active a {
                    text-decoration: underline;
                }
            }
        }
    }
	/* header langs */

    &.header {
        border-bottom: 2px solid #af8a52;
        background-color: rgba(255,251,246,.5);
        .slogan {
            display: block;
            font-size: 46px;
            font-weight: bold;
            color: #af8a52;
            text-align: center;
            text-transform: uppercase;
            outline: none;
            margin: 31px 0;
        }
        .langs {
            max-height: 25px;
            top: 45px;
            right: 60px;
            &:after {
                top: 7px;
            }
            ul li a {
                color: #af8a52;
                font-size: 20px;
            }
            &:hover {
                max-height: 150px;
                ul li a {
                    color: #fff;
                }
            }
        }
    }
	@media screen and (max-width: 1440px) {
        &.header {
            .slogan {
                font-size: 34px;
            }
            .langs {
                top: 35px;
            }
        }
    }
	@media screen and (max-width: 1170px) {
        .container {
            width: 100%;
        }
	}
    @media screen and (max-width: 1140px) {
        background-color: #f2ebdf;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
        .up {
            background-color: #e1d3bc;
            box-shadow: none;
        }
        .logo {
            display: table;
            height: auto;
            padding: 10px 0;
            box-shadow: none;
            margin: 0 auto;
            &:after {
                bottom: 0;
            }
        }
        .slogan {
            margin-left: 15px;
        }
        .phones {
            margin-left: 35px;
        }
        .nav {
            display: none;
            width: 100%;
            box-shadow: none;
            border-top: 2px solid #b49157;
            text-align: center;
            text-align-last: center;
            padding: 0;
            > ul > li {
                display: block;
                margin: 8px 0;
                > a {
                    display: table;
                    padding: 0px 0px 8px;
                    margin: 0 auto;
                    &:hover {
                        &:before {
                            border-top: 0px solid #b49157;
                            border-bottom: 2px solid #b49157;
                            opacity: 1;
                        }
                    }
                }
                &.active > a {
                    &:before {
                        border-top: 0px solid #b49157;
                        border-bottom: 2px solid #b49157;
                        opacity: 1;
                    }
                }
            }
        }
        .nav--btn {
            display: block;
            width: 35px;
            height: 30px;
            position: absolute;
            left: 12px;
            top: 55px;
            padding: 0;
            margin: 0;
            outline: none;
            float: none;
            z-index: 1;
            span {
                position: absolute;
                left: 0;
                right: 0;
                width: 85%;
                height: 3px;
                margin: 0 auto;
                background-color: #af8a52;
                transition: 350ms;
                &:nth-of-type(1) {
                    top: 7px;
                }
                &:nth-of-type(2) {
                    top: 14px;
                }
                &:nth-of-type(3) {
                    top: 21px;
                }
            }
            &.open {
                span {
                    &:nth-of-type(1) {
                        top: 14px;
                        transform: rotate(45deg);
                    }
                    &:nth-of-type(2) {
                        opacity: 0;
                    }
                    &:nth-of-type(3) {
                        top: 14px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
        .phones {
            display: none;
            background-color: rgba(200, 175, 137, 0.9);
            text-align: center;
            padding: 10px;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 3;
            margin: 0;
            &:after {
                display: none;
            }
            ul {
                span {
                    display: none;
                }
                li {
                    display: block;
                    margin: 5px 0;
                    a {
                        font-size: 18px;
                    }
                }
            }
        }
        .phones--btn {
            display: block;
            font-family: 'fontAwesome';
            font-size: 22px;
            color: #af8a52;
            position: absolute;
            right: 15px;
            top: 58px;
            z-index: 1;
            &:after {
                content: '\f095';
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 680px) {
        .choose--btn {
            width: 140px;
            height: 25px;
            font-size: 12px;
            text-align: left;
            padding: 7px 10px 6px 25px;
            &:after {
                font-size: 15px;
                top: 4px;
                left: 8px!important;
            }
        }
        .slogan {
            font-size: 14px;
            margin: 5px 0px 0px 10px;
        }
        .langs {
            right: auto;
            top: 27px;
            left: 5px;
            z-index: 2;
        }
        .nav--btn {
            top: 50px;
            left: 7px;
        }
        .phones--btn {
            top: 44px;
        }
        .search--btn {
            width: 20px;
            height: 20px;
            top: 46px;
            right: 40px;
            z-index: 1;
        }
        .search {
            top: 40px;
            right: 37px;
            z-index: 2;
        }
        &.header {
            .langs {
                left: auto;
                right: 10px;
                top: 25px;
            }
            .slogan {
                display: table;
                font-size: 26px;
                margin: 25px auto;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .logo {
            height: 62px;
            width: 180px;
            font-size: 34px;
            padding-top: 12px;
            &:after {
                bottom: 7px;
            }
        }
        .choose--btn {
            width: 115px;
            font-size: 10px;
            padding: 8px 5px 7px 20px;
            &:after {
                font-size: 12px;
                left: 5px!important;
                top: 6px;
            }
        }
        .slogan {
            font-size: 12px;
        }
        .search {
            top: 87px;
            right: 0;
            &.open {
                width: 100%;
            }
            input[type=search] {
                padding: 7px 15px 4px 15px;
            }
        }
        &.header {
            .slogan {
                font-size: 18px;
                margin: 31px auto;
            }
        }
    }
}

[data-lang="en"] {
    header {
        .choose--btn {
            text-align: right;
        }
    }
    @media screen and (max-width: 680px) {
        header {
            .choose--btn {
                font-size: 11px;
                padding-top: 7px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        header {
            .choose--btn {
                font-size: 9px;
                padding-top: 8px;
            }
        }
    }
}

[data-lang="kz"] {
    header {
        .choose--btn {
            padding-left: 20px;
            padding-top: 12px;
            font-size: 13px;
            text-align: right;
            &:after {
                left: 20px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        header {
            .choose--btn {
                font-size: 11px;
                padding-top: 3px;
            }
        }
    }
}
/* header */